<template>
  <v-container justify="space-between" class="d-flex flex-column h-screen">
    <v-row>
      <v-col>
        <v-img class="mx-auto" width="143" height="60" src="@/assets/logo.svg" alt="Logo" />
      </v-col>
    </v-row>

    <v-row class="flex-grow-4 my-4">
      <v-col xs=12 sm=8 md=5 lg=4 xl=3 class="mx-auto">
        <h2 class="text-center">Password restore</h2>

        <v-form v-if="state === 'init'" @submit="restore">
          <v-text-field
            v-model="email"
            class="my-4"
            density="compact"
            hide-details
            label="Email"
            prepend-inner-icon="mdi-account-outline"
            type="email"
            variant="outlined" />

          <v-btn type="submit" block flat color="secondary">Restore</v-btn>
        </v-form>

        <div v-if="state === 'sent_email'" class="mt-4 text-center">
          Письмо с кодом для смены пароля было отправлено на адрес <b>{{ email }}</b>.
          Если письмо не пришло, проверьте, пожалуйста, папку Spam.
        </div>

        <div v-if="state == 'change_password'" class="mt-4 text-center">
          Доступ успешно восстановлен! Не забудьте сменить пароль...
        </div>
      </v-col>
    </v-row>

    <v-footer class="flex-grow-0 mx-auto">
        <span>Добро пожаловать!</span>
    </v-footer>

  </v-container>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import router from '../router'
import userStore from '../store/user'


const name = 'PasswordRecoveryView'
const user = userStore()

const state = ref('init')

const email = ref()
const password = ref()

onBeforeMount(() => {
  if (user.authenticated)
    router.push('/')

  if (router.currentRoute.value.query.flow)
    state.value = 'change_password'
})

async function restore() {
  await user.sendRestorePasswordMessage(email.value)

  state.value = 'sent_email'
}

async function changePassword() {
  let flow = router.currentRoute.value.query.flow
  let token = router.currentRoute.value.query.token

  await user.changePassword(flow, token, password.value)
}

</script>
