<template>
  <v-container justify="space-between" class="d-flex flex-column h-screen">
    <v-row>
      <v-col>
        <v-img class="mx-auto" width="143" height="60" src="@/assets/logo.svg" alt="Logo" />
      </v-col>
    </v-row>

    <v-row class="flex-grow-4 my-4">
      <v-col xs=12 sm=8 md=5 lg=4 xl=3 class="mx-auto">
        <h2 class="text-center">Sign in</h2>

        <v-form @submit="login">
          <v-text-field
            v-model="email"
            class="mt-4"
            density="compact"
            hide-details
            label="Email"
            prepend-inner-icon="mdi-account-outline"
            type="email"
            variant="outlined" />

          <v-text-field
            v-model="password"
            class="mt-4"
            density="compact"
            hide-details
            label="Password" 
            prepend-inner-icon="mdi-lock-outline"
            type="password"
            variant="outlined" />

          <div class="text-center my-4">
            <router-link to="/recovery">Forgot password?</router-link>
          </div>

          <v-btn type="submit" block flat color="secondary">Sign in</v-btn>
        </v-form>
      </v-col>
    </v-row>

    <!-- FIXME: -->
    <!--v-footer class="flex-grow-0 mx-auto">
        <span>Don't have account? <b><router-link to="/signup">Sign up!</router-link></b></span>
    </v-footer-->

  </v-container>
</template>

<style>
.h-screen {
  min-height: 100vh;
}

.flex-grow-4 {
  flex-grow: 4 !important;
}
</style>

<script setup>
import { ref, onBeforeMount } from 'vue'
import router from '../router'
import userStore from '../store/user'


const name = 'LoginView'
const user = userStore()

const email = ref()
const password = ref()

onBeforeMount(() => {
  // FIXME: go to previous url
  if (user.authenticated)
    router.push('/')
})

async function login() {
  // FIXME: add loader

  await user.loginWithPassword(email.value, password.value)

  // FIXME: can get prev call data 
  await user.checkSession()

  // FIXME: go to previous url
  router.push('/')
}
</script>
