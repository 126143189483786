<template>
  <v-container>
    <v-row class="mt-4">
      <v-col cols=1>Name:</v-col>
      <v-col>{{ user.realname }}</v-col>
    </v-row>
    <v-row>
      <v-col cols=1>Email:</v-col>
      <v-col>{{ user.email }}</v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col>
        <v-btn href="/password">Поменять пароль</v-btn>
      </v-col>
      <v-col>
        <v-btn @click="logout">Выйти</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import router from '../router'
import userStore from '../store/user'

const name = 'ProfileView'

const user = userStore()

async function logout() {
  await user.logout()

  router.push('/')
}

</script>
