<template>
  <v-container justify="space-between" class="d-flex flex-column h-screen">
    <v-row>
      <v-col>
        <v-img class="mx-auto" width="143" height="60" src="@/assets/logo.svg" alt="Logo" />
      </v-col>
    </v-row>

    <v-row class="flex-grow-4 my-4">
      <v-col xs=12 sm=12 md=8 lg=6 xl=6 class="mx-auto">
        <h2>{{ obj.name }}</h2>

        <div class="mt-4">
          {{ obj.greeting }}
        </div>

        <div class="mt-4">
          <p><b>Адрес:</b></p>
          <a :href="obj.map" target="_blank">{{ obj.address }}</a>
        </div>

        <div class="mt-4">
          <p @click="displayEntranceInfo = !displayEntranceInfo"><b>Как войти?</b></p>
          <ul v-show="displayEntranceInfo" class="ml-4">
            <li v-for="line in obj.entrance" v-bind:key="line">
            {{ line }}
            </li>
            <li v-if="obj.smartLockId">
              <v-btn type="" flat color="secondary">Откройте умный замок</v-btn>
            </li>
          </ul>
        </div>

        <div class="mt-4">
          <p><b>Правила:</b></p>
          <p class="ml-4"><v-icon icon="mdi-smoking-off" size="x-large" class="mr-2" color="red" />У нас не курят!</p>
          <p class="ml-4"><v-icon icon="mdi-sleep" size="x-large" class="mr-2" color="red" />Нельзя шуметь с 22:00 до 9:00</p>
          <p class="ml-4"><v-icon icon="mdi-camera" size="x-large" class="mr-2" color="orange" />Коммерческие фото/видеосъемки согласовываются отдельно</p>
          <p class="ml-4"><v-icon icon="mdi-account-group" size="x-large" class="mr-2" color="orange" />Не более 3-х гостей (иначе необходимо доплатить)</p>
        </div>

        <div class="mt-4">
          <p><b>Инструкции:</b></p>
          <p class="ml-4"><v-icon icon="mdi-wifi" size="x-large" class="mr-2" color="secondary" />{{ obj.info.wifi }}</p>
          <p class="ml-4"><v-icon icon="mdi-television" size="x-large" class="mr-2" color="secondary" />Нажмите на пульте "настройки", выберите AirCast, после этого подключитесь с iphone</p>
        </div>

        <div class="mt-4">
          <p><b>Нужна помощь?</b></p>
          <p>
            <a :href="obj.links.phone" target="_blank" class="mr-2"><v-icon icon="mdi-phone" size="x-large" /></a>
            <a :href="obj.links.whatsapp" target="_blank" class="mr-2"><v-icon icon="mdi-whatsapp" size="x-large" /></a>
          </p>
        </div>

      </v-col>
    </v-row>

    <!-- FIXME: -->
    <!--v-footer class="flex-grow-0 mx-auto">
        <span>Don't have account? <b><router-link to="/signup">Sign up!</router-link></b></span>
    </v-footer-->

  </v-container>
</template>

<style>
.h-screen {
  min-height: 100vh;
}

.flex-grow-4 {
  flex-grow: 4 !important;
}
</style>

<script setup>
import { ref, onBeforeMount } from 'vue'
import router from '../router'
import objectStore from '../store/object'


const name = 'ObjectView'
const props = defineProps({
  objId: String,
})

const obj = ref()
obj.value = objectStore().find(props.objId)

const displayEntranceInfo = ref(true)

onBeforeMount(() => {
  // FIXME: go to previous url
  // if (user.authenticated)
  //   router.push('/')
})

async function login() {
  // FIXME: add loader

  await user.loginWithPassword(email.value, password.value)

  // FIXME: can get prev call data 
  await user.checkSession()

  // FIXME: go to previous url
  router.push('/')
}
</script>
