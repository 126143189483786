<template>
  <v-container justify="space-between" class="d-flex flex-column h-screen">
    <v-row>
      <v-col>
        <v-img class="mx-auto" width="143" height="60" src="@/assets/logo.svg" alt="Logo" />
      </v-col>
    </v-row>

    <v-row class="flex-grow-4 my-4">
      <v-col xs=12 sm=8 md=5 lg=4 xl=3 class="mx-auto">
        <h2 class="text-center">Create account</h2>

        <v-form @submit="signup">
          <v-text-field
            v-model="realname"
            class="mt-4"
            density="compact"
            hide-details
            label="Your name"
            prepend-inner-icon="mdi-account-outline"
            type="text"
            variant="outlined" />

          <v-text-field
            v-model="email"
            class="mt-4"
            density="compact"
            hide-details
            label="Email"
            prepend-inner-icon="mdi-email-outline"
            type="email"
            variant="outlined" />

          <v-text-field
            v-model="password"
            class="mt-4"
            density="compact"
            hide-details
            label="Password" 
            prepend-inner-icon="mdi-lock-outline"
            type="password"
            variant="outlined" />

          <div class="text-center my-4">
            At least 8 characters containing at least 1 number, 1 lowercase letter and 1 uppercase letter.
          </div>

          <v-btn type="submit" block flat color="secondary">Create account</v-btn>

          <div class="text-center mt-4">
            By creating account you agree to our <a href="https://keypository.ru/termsofuse/">Terms of Use</a>
          </div>
        </v-form>
      </v-col>
    </v-row>

    <v-footer class="flex-grow-0 mx-auto">
        <span>Already have an account? <b><router-link to="/login">Sign in</router-link></b></span>
    </v-footer>

  </v-container>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import router from '../router'
import userStore from '../store/user'

const name = 'SignupView'
const user = userStore()

const email = ref()
const realname = ref()
const password = ref()

onBeforeMount(() => {
  if (user.authenticated)
    router.push('/')
})

async function signup() {
  await user.signupWithPassword(realname.value, email.value, password.value)

  router.push('/')
}
</script>
