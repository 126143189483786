import { defineStore } from 'pinia'

const Objects = {
  'lahify': {
    name: 'Бейкер-стрит',
    greeting: 'Добро пожаловать в наши аппартаменты "Бейкер-стрит"! Мы уверены, что они вам понравятся. Ниже вы найдете подробную информацию как добраться и как сделать свое пребывание максимально комфортным.',

    address: 'Москва, Большой Гнездниковский переулок, 10, кв. 511',
    map: 'https://yandex.ru/maps/-/CCUJbNb6KD',
    
    entrance: [
      '2-й подъезд, код 511в7711',
      '5-й этаж, используйте любой из лифтов',
      'Следуйте по указателям до 511 квартиры',
    ],

    info: {
      wifi: 'сеть RT_00001, пароль su2og3bx'
    },

    links: {
      phone: 'tel:+79266622387',
      whatsapp: 'https://wa.me/79266622387'
    },

    smartLockId: 'test1',
  },

  'f8rt5c' : {
    name: 'River Home Moscow',
    greeting: 'Добро пожаловать в аппартаменты "River Home Moscow" - самую уютную студию для комфортной раобты и отдыха! Надеемся, что приведенные здесь подсказки и правила помогут вам чувствовать себя как дома.',

    address: 'Москва, ул. Корабельная, 6',
    map: 'https://yandex.ru/maps/-/CCUJb6S6CC',
    
    entrance: [
      'Дом состоит из 3-х башен. Вам нужна дальняя, если вы заезжаете со стороны ул. Коломенская и ближайшая, если вы движетесь по ул. Корабельная',
      'В каждой башне по одному подъезду, они расположены по стороны наземного паркинга.',
      'Код домофона XXXX', //В0064ВВ',
      '9-й этаж, используйте любой из лифтов',
      'Следуйте до апартаментов 64',
      'Справа от двери мини-сейф с ключами, код XXXX', //0524',
    ],

    info: {
      wifi: 'сеть River Home Moscow, пароль XXXXXXXX' //River0064'
    },

    links: {
      phone: 'tel:+79032538234',
      whatsapp: 'https://wa.me/79032538234'
    },
  },
}

function find(id) {
  return Objects[id]
}

function list() {
  return Object.keys(Objects).map(id => {
    let o = Objects[id]
    return {id: id, name: o.name, address: o.address}
  })
}

export default defineStore({
  id: 'object',

  //state: () => ({
  //}),

  actions: {
    find,
    list,
  }
})
