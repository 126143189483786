import { defineStore } from 'pinia'
import { Configuration, V0alpha2Api } from '@ory/kratos-client'

const apiBaseUrl = '/api'

const conf = new Configuration({basePath: apiBaseUrl})
const kapi = new V0alpha2Api(conf)

async function init() {
  if (this.initialized)
    return

  await this.checkSession()

  this.initialized = true
}

function clearIdentity(user) {
  user.authenticated = false
  // user.verified = undefined
  user.realname = undefined
  user.email = undefined
}

function setIdentity(user, identity) {
  user.authenticated = true
  // user.verified = resp.data.identity.verifiable_addresses[0].verified
  user.email = identity.traits.email
  user.realname = identity.traits.realname
}

async function changePassword(password) {
  let resp = await kapi.initializeSelfServiceSettingsFlowForBrowsers()

  let flow_id = resp.data.id
  let csrf_token = resp.data.ui.nodes.find(n => {return n.attributes.name == 'csrf_token'}).attributes.value


  //let body = {method: 'password', csrf_token, password}
  let body = {method: 'profile', csrf_token, traits: { realname: 'Alex', email: 'ag@keypository.com'}}

  resp = await kapi.submitSelfServiceSettingsFlow(flow_id, csrf_token, body).catch(e => e)
  console.log(resp)

  // body = {identifier: 'ag@keypository.com', method: 'password', password: 'aaaAAA42!', csrf_token}

  // resp = await kapi.submitSelfServiceLoginFlow(flow_id, csrf_token, body)
  // console.log(resp)

  //if (resp.data.state !== 'sent_email')
  //  throw `Invalid state ${resp.data.state}`
}

async function checkSession() {
  await kapi
          .toSession()
          .then(resp => {
            setIdentity(this, resp.data.identity)
          })
          .catch(e => {
            clearIdentity(this)
          })
}

async function loginWithPassword(username, password) {
  let resp = await kapi.initializeSelfServiceLoginFlowForBrowsers()

  let flow_id = resp.data.id
  let csrf_token = resp.data.ui.nodes.find(n => {return n.attributes.name == 'csrf_token'}).attributes.value

  let body = {identifier: username, method: 'password', password: password, csrf_token: csrf_token}

  resp = await kapi.submitSelfServiceLoginFlow(flow_id, csrf_token, body)

  setIdentity(this, resp.data.session.identity)
}

async function logout() {
  let resp = await kapi.createSelfServiceLogoutFlowUrlForBrowsers()
  let token = resp.data.logout_token

  await kapi.submitSelfServiceLogoutFlow(token)

  clearIdentity(this)
}

async function sendRestorePasswordMessage(email) {
  let resp = await kapi.initializeSelfServiceRecoveryFlowForBrowsers()

  let flow_id = resp.data.id
  let csrf_token = resp.data.ui.nodes.find(n => {return n.attributes.name == 'csrf_token'}).attributes.value

  let body = {method: 'link', csrf_token, email}
  let headers = {'X-Session-Token': csrf_token}

  resp = await kapi.submitSelfServiceRecoveryFlow(flow_id, undefined, body, {headers})

  if (resp.data.state !== 'sent_email')
    throw `Invalid state ${resp.data.state}`
}

async function signupWithPassword(realname, username, password) {
  let resp = await kapi.initializeSelfServiceRegistrationFlowForBrowsers()

  let flow_id = resp.data.id
  let csrf_token = resp.data.ui.nodes.find(n => {return n.attributes.name == 'csrf_token'}).attributes.value

  let traits = {email: username, realname: realname}
  let body = {method: 'password', password, csrf_token, traits}
  let headers = {'X-Session-Token': csrf_token}

  resp = await kapi.submitSelfServiceRegistrationFlow(flow_id, body, {headers})

  setIdentity(this, resp.data.session.identity)
}


export default defineStore({
  id: 'main',

  state: () => ({
    api: kapi,
    initialized: false,
    authenticated: false,
    verified: undefined,
    email: undefined,
    realname: undefined,
  }),

  actions: {
    init,
    changePassword,
    checkSession,
    loginWithPassword,
    logout,
    sendRestorePasswordMessage,
    signupWithPassword,
  }
})
