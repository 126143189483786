import { createRouter, createWebHistory } from 'vue-router';
import userStore from '../store/user'
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import PasswordChangeView from '../views/PasswordChangeView.vue';
import PasswordRecoveryView from '../views/PasswordRecoveryView.vue';
import ProfileView from '../views/ProfileView.vue';
import SignupView from '../views/SignupView.vue';
import VerificationView from '../views/VerificationView.vue';
import ObjectView from '../views/ObjectView.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: { public: true },
  },
  {
    path: '/password',
    name: 'PasswordChange',
    component: PasswordChangeView,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView,
  },
  {
    path: '/recovery',
    name: 'PasswordRecovery',
    component: PasswordRecoveryView,
    meta: { public: true },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: SignupView,
    meta: { public: true },
  },
  {
    path: '/verify',
    name: 'Verify',
    component: VerificationView,
  },
  {
    path: '/o/:objId',
    name: 'Object',
    component: ObjectView,
    props: true,
    meta: { public: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  let user = userStore()

  if (!user.initialized)
    await user.init()

  if (!user.authenticated && !to.meta.public)
    return '/login'

  //if (user.authenticated && !user.verified && to.path !== '/verify')
  //  return '/verify'
})

export default router;
