<template>
  <v-container justify="space-between" class="d-flex flex-column h-screen">
    <v-row class="flex-grow-4 my-4">
      <v-col xs=12 sm=8 md=5 lg=4 xl=3 class="mx-auto">
        <h2 class="text-center">Change password</h2>

        <v-form @submit="changePassword">
          <v-text-field
            v-model="password"
            class="mt-4"
            density="compact"
            hide-details
            label="Password" 
            prepend-inner-icon="mdi-lock-outline"
            type="password"
            variant="outlined" />

          <div class="text-center my-4">
            At least 8 characters containing at least 1 number, 1 lowercase letter and 1 uppercase letter.
          </div>

          <v-btn type="submit" block flat color="secondary">Save</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import router from '../router'
import userStore from '../store/user'

const name = 'PasswordChangeView'
const user = userStore()

const password = ref()

async function changePassword() {
  await user.changePassword(password.value)

  router.push('/')
}
</script>
