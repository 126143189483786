<template>
  <v-container justify="space-between" class="d-flex flex-column h-screen">
    <v-row>
      <v-col>
        <v-img class="mx-auto" width="143" height="60" src="@/assets/logo.svg" alt="Logo" />
      </v-col>
    </v-row>

    <v-row class="flex-grow-4 my-4">
      <v-col xs=12 sm=8 md=5 lg=4 xl=3 class="mx-auto">
        <h2 class="text-center">Email verification</h2>

        <div class="text-center my-4">
          Вам отправлено письмо с кодом подтверждения
        </div>

        <v-form @submit="verify">
          <v-text-field
            v-model="verificationCode"
            class="mt-4"
            density="compact"
            hide-details
            label="Verification code"
            prepend-inner-icon="mdi-account-outline"
            type="text"
            variant="outlined" />

          <v-btn type="submit" block flat class="mt-4" color="secondary">Подтвердить</v-btn>

          <v-btn @click="resend" block flat class="mt-4" color="grey">Отправить письмо повторно</v-btn>
        </v-form>
      </v-col>
    </v-row>

    <v-footer class="flex-grow-0 mx-auto">
        <span>Добро пожаловать!</span>
    </v-footer>

  </v-container>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import router from '../router'
import userStore from '../store/user'

const name = 'VerificationView'
const user = userStore()

const verificationCode = ref()

onBeforeMount(() => {
  if (user.verified)
    router.push('/')
})

function verify() {
  // FIXME: add loader

  // make api calls

  if (verificationCode.value !== '666666') {
    // FIXME: add error display
    verificationCode.value = ''

    return
  }

  //user.verified = true
  router.push('/')
}

function resend() {
  // make api calls

  alert('code was sent to your email...')
}

</script>
