<template>
  <v-container>
    <v-row class="d-flex">
      <v-col
          v-for="appartament in appartaments"
          :key="appartament.name"
          align="stretch"
          cols=12
          sm=6
          lg=4
      >
        <v-card
            :title="appartament.name"
            :text="appartament.address"
            :href="'/o/' + appartament.id"
            class="fill-height"
            />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import objectStore from '../store/object'


const name = 'HomeView'

const appartaments = objectStore().list()

</script>
