<template>
  <v-app>
    <v-app-bar v-if="user.authenticated && !router.currentRoute.value.meta.public">
      <v-container class="d-flex py-0" style="align-items: center;">
        <v-img
            alt="Logo"
            class="mr-4"
            max-height="48"
            max-width="114"
            min-width="114"
            src="@/assets/logo.svg"
            />

        <v-btn to="/">
          Квартиры
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn large to="/profile">
            <v-icon icon="mdi-account-circle" size="x-large"></v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script setup>
import router from './router'
import userStore from './store/user'

document.title = 'Keypository - Личный Кабинет'

const name = 'App'

const user = userStore()

// onBeforeMount(async () => {
//   await user.checkSession()
// })

</script>
